import React from 'react';
import { connect } from 'react-redux';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { updateCursor, updateWindow } from '../state/state-movement';
import { graphql } from 'gatsby';
import Img from "gatsby-image"
import Services from '../svg/services-icon.svg'
import renderHTML from 'react-render-html';
import Logo from '../svg/white-logo.svg'
import Arrow from '../svg/left-arrow.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink";

class ServicesPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      contentActive: false
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({contentActive: true});
    }, 100);
  }

  render () {
    let data = this.props.data.services;
    let depth = this.props.depth;
    let movement = this.props.movement;

    return (
      <>
        <SEO title="Collaboration" bodyType="" description={data.seoDescription}/>
        <Layout 
          transitionStatus={this.props.transitionStatus}
          transitionDirection={this.props.current.state.direction}
          class={`services`}
        >
          <div className={`content ${this.state.contentActive && 'content-active'}`}>
            <div className="content-header">
              <div className="content-header__icon">
                <Services />
              </div>
              <h1 className="content-header__title collaboration">Collaboration</h1>
            </div>
            <div className="content-body">
              <div className="content-body__left">
                {renderHTML(data.servicesText)}
                <AniLink to={`/contact`} fade duration={1}>
                  <span className="content-link__text">Why not drop us a line!</span>
                  <Arrow/>
                </AniLink>
              </div>
              <div className="content-body__right">
                <Img fluid={data.servicesImage.fluid} alt={data.servicesImage.alt}/>
              </div>
            </div>
            <Logo className="content-logo about"/>
          </div>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    services: datoCmsService {
      seoDescription
      servicesText
      servicesImage {
        fluid {
          ...GatsbyDatoCmsFluid
        }
        url 
        alt
      }
    }
  }
`

export default connect(
  state => ({ 
    window: state.movementState.windowDimensions,
    depth: state.movementState.depthLevels,
    movement: state.movementState,
  }),
  dispatch => ({ 
    updateCursor: cursorObj => dispatch(updateCursor(cursorObj)),
    updateWindow: windowObj => dispatch(updateWindow(windowObj)),
  }),
)(ServicesPage);